import Link from "./Link";
import { useQuery, gql } from "@apollo/client";

const FEED_QUERY = gql`
  {
    feed {
      id
      url
      description
    }
  }
`;

export const LinkList = () => {
  const { data } = useQuery(FEED_QUERY);

  return (
    <div>
      {data && (
        <>
          {data.feed.map((link: any) => (
            <Link key={link.id} link={link} />
          ))}
        </>
      )}
    </div>
  );
};
