import * as React from "react";
import { useQuery, gql } from "@apollo/client";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TablePagination,
  TableCell,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import TableSortLabel from "@mui/material/TableSortLabel";

import { FormatHeader } from "./FormatHeader";
import { FormatValue } from "./FormatValue";

import { Item } from "./Item";

import TableContainer from "@mui/material/TableContainer";
import { StyledTableRow } from "./StyledTableRow";

import { Outlet } from "react-router-dom";
import {
  Select,
  MenuItem,
  Button,
  Paper,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import { Slider } from "./slicers/Slider";

import {
  LanguageContext,
  LanguageContextType,
} from "../context/languageContext";
import {
  ScreenerContext,
  ScreenerContextType,
} from "../context/screenerContext";
import { StyledTableCell } from "./StyledTableCell";

const FEED_QUERY = gql`
  query Fund($filter: [FundFilter], $slicers: FundValuesFilter) {
    fund(filter: $filter) {
      ticker
      name
      price
      nav
      yieldPrice
      discount
      zScore1
      leverageRatio
      totalReturnNav5
      riskPyramid
      returnOnCapital3
      earningsCoverage
      unii
      uniiTrend
      navGrowth5
      navGrowth10
      marketCap
      dxyxzScore
      category {
        categoryName
        id
      }
    }
    fundValues(filter: $slicers) {
      fieldName
      max
      min
    }
  }
`;

const slicers = [
  "yieldPrice",
  "earningsCoverage",
  "returnOnCapital3",
  "discount",
  "totalReturnNav5",
  "leverageRatio",
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface Data {
  ticker: string;
  name: number;
  price: number;
  yieldPrice: number;
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: keyof Data;
  rowCount: number;
  data: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  const { language, translate } = React.useContext(
    LanguageContext
  ) as LanguageContextType;

  console.log(props);
  return (
    <TableHead>
      <TableRow>
        {Object.keys(props.data.fund[0]).map((headCell) => (
          <FormatHeader
            fieldName={headCell}
            key={headCell}
            sortDirection={orderBy === headCell ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell}
              direction={orderBy === headCell ? order : "asc"}
              onClick={createSortHandler(headCell as keyof Data)}
            >
              {translate(headCell)}
              {orderBy === headCell ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </FormatHeader>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function BasicTable() {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("ticker");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const { filter, setAddFilter, resetFilter } = React.useContext(
    ScreenerContext
  ) as ScreenerContextType;

  const { language, translate } = React.useContext(
    LanguageContext
  ) as LanguageContextType;

  const { error, loading, data } = useQuery(FEED_QUERY, {
    variables: {
      ...filter,
      slicers: {
        fieldName: slicers,
      },
    },
  });

  if (loading) return <>Loading..</>;
  if (error) return <>Error..</>;

  const typeFilter = (event: any) => {
    setAddFilter({ fundType: event.target.value });
  };

  const minValue = (field: string) => {
    const value = data.fundValues.find((item: any) => item.fieldName === field);
    return value.min;
  };
  const maxValue = (field: string) => {
    const value = data.fundValues.find((item: any) => item.fieldName === field);
    return value.max;
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={4}>
          <Item>
            <Button onClick={resetFilter}>Reset</Button>
          </Item>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Item>
            <Select onChange={typeFilter} value={filter?.filter[0]?.fundType}>
              <MenuItem value="CEF">CEF</MenuItem>
              <MenuItem value="BDC">BDC</MenuItem>
            </Select>
          </Item>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Item>
            <Typography variant="h2">{data.fund.length}</Typography>
            <Typography>Funds filtered</Typography>
          </Item>
        </Grid>

        {slicers.map((slicer) => {
          return (
            <Slider
              field={slicer}
              min={minValue(slicer)}
              max={maxValue(slicer)}
            />
          );
        })}
      </Grid>

      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data.fund.length}
              data={data}
            />
            {/*             <TableHead>
              <TableRow key="header">
                {Object.keys(data.fund[0]).map((key, index) => {
                  return <FormatHeader fieldName={key} />;
                })}
              </TableRow>
            </TableHead> */}
            <TableBody key="body">
              {stableSort(data.fund, getComparator(order, orderBy))
                .sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any) => (
                  <StyledTableRow
                    key={row.ticker}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {Object.keys(data.fund[0]).map((key, index) => {
                      return <FormatValue fieldName={key} value={row[key]} />;
                    })}
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.fund.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      <Outlet />
    </>
  );
}
