import * as React from "react";

import {
  LanguageContext,
  LanguageContextType,
} from "../../context/languageContext";

export interface MenuItemsInterface {
  name: String;
  link: String;
}

export const MenuItems = (): Array<MenuItemsInterface> => {
  const { language, translate } = React.useContext(
    LanguageContext
  ) as LanguageContextType;

  return [
    { name: translate("menuHome"), link: `/${language}/home` },
    { name: translate("menuListFunds"), link: `/${language}/funds` },
  ];
};
