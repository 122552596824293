import * as React from "react";
import { percentageFields, numberFields, priceFields } from "../data";
import { StyledTableCell } from "./StyledTableCell";
import {
  LanguageContext,
  LanguageContextType,
} from "../context/languageContext";

export const FormatHeader = (props: any) => {
  const { translate } = React.useContext(
    LanguageContext
  ) as LanguageContextType;

  if (percentageFields.includes(props.fieldName))
    return <StyledTableCell align="right">{props.children}</StyledTableCell>;
  if (numberFields.includes(props.fieldName))
    return <StyledTableCell align="right">{props.children}</StyledTableCell>;
  if (priceFields.includes(props.fieldName))
    return <StyledTableCell align="right">{props.children}</StyledTableCell>;
  if (props.fieldName === "__typename") return <></>;
  return <StyledTableCell>{props.children}</StyledTableCell>;
};
