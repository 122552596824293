import * as React from "react";
import { Menu, MenuItem, IconButton } from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";
import {
  LanguageContext,
  LanguageContextType,
} from "../../context/languageContext";

export const LanguageIcon = () => {
  const { languages, setLanguage } = React.useContext(
    LanguageContext
  ) as LanguageContextType;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setLanguageAndClose = (language: string) => {
    setLanguage(language);
    handleClose();
  };

  return (
    <div>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <TranslateIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {languages.map((item) => (
          <MenuItem
            key={item}
            value={item}
            onClick={() => setLanguageAndClose(item)}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
