import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./components/App";
import reportWebVitals from "./reportWebVitals";
// import { render } from "react-dom";
import { LinkList } from "./components/LinkList";
import BasicTable from "./components/Table";
import { Detail } from "./components/Detail";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import { LanguageProvider } from "./context/languageContext";
import { ScreenerProvider } from "./context/screenerContext";
import { SnackbarProvider } from "notistack";
import { onError } from "@apollo/client/link/error";
import { Dashboard } from "./components/Dashboard";

import { init as initApm } from "@elastic/apm-rum";

import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
} from "@apollo/client";

const apm = initApm({
  // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  serviceName: "cefscreener",

  // Set custom APM Server URL (default: http://localhost:8200)
  serverUrl:
    "https://84146d1c3eb844dfbde6837a036cb710.apm.westeurope.azure.elastic-cloud.com",

  // Set service version (required for sourcemap feature)
  serviceVersion: "1.0",
});

const uri =
  process.env.NODE_ENV === "production"
    ? "https://graphql.cefscreener.com/graphql"
    : "http://localhost:4000/graphql";

const httpLink = createHttpLink({
  uri,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach((error) => {
      const stacktrace = error?.extensions?.stacktrace;
      console.log(`[GraphQL stack]: ${error}`);
      //@ts-expect-error
      apm.captureError(stacktrace.join("\r\n"));
      apm.captureError(error);
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache(),
  //@ts-expect-error
  formatError: (formattedError, error) => {
    console.log(error);
  },
  //@ts-expect-error
  onError: ({ networkError, graphQLErrors }) => {
    console.log("graphQLErrors", graphQLErrors);
    console.log("networkError", networkError);
  },
});

// const rootElement = document.getElementById("root");

// render(<App />, rootElement);

const theme = createTheme({});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <SnackbarProvider maxSnack={3}>
      <ApolloProvider client={client}>
        <ScreenerProvider>
          <LanguageProvider>
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <Routes>
                  <Route
                    path="/"
                    element={<Navigate replace to="/en/home" />}
                  />
                  <Route path="/:languageId/" element={<App uri={uri} />}>
                    <Route path="home" element={<Dashboard />} />
                    <Route path="expenses" element={<LinkList />} />
                    <Route path="funds" element={<BasicTable />} />
                    <Route path="funds/:ticker" element={<Detail />} />
                    <Route
                      path="*"
                      element={
                        <main style={{ padding: "1rem" }}>
                          <p>There's nothing here!</p>
                        </main>
                      }
                    />
                  </Route>
                </Routes>
              </BrowserRouter>
            </ThemeProvider>
          </LanguageProvider>
        </ScreenerProvider>
      </ApolloProvider>
    </SnackbarProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
