import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { Link } from "react-router-dom";

export function DashboardCard() {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea component={Link} to={"/en/funds"}>
        <CardMedia
          component="img"
          height="140"
          image="/cef-screener.png"
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Screener
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Go to the closed-end-fund screener to find funds. All data is
            updated on a daily base.
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small">TAKE ME TO THE ACTION</Button>
        </CardActions>
      </CardActionArea>
    </Card>
  );
}
