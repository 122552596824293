import * as React from "react";
import { formatPercentage } from "../utils";
import {
  ScreenerContext,
  ScreenerContextType,
} from "../../context/screenerContext";
import {
  Slider as MuiSlider,
  Box,
  Typography,
  Grid,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import {
  LanguageContext,
  LanguageContextType,
} from "../../context/languageContext";
interface SliderProps {
  field: string;
  min?: GLfloat;
  max?: GLfloat;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export const Slider = ({ field, min = 0, max = 1 }: SliderProps) => {
  const { filter, setAddFilter } = React.useContext(
    ScreenerContext
  ) as ScreenerContextType;
  const { language, translate } = React.useContext(
    LanguageContext
  ) as LanguageContextType;

  const gte = filter?.filter[0]?.[field]?.gte
    ? filter?.filter[0]?.[field]?.gte
    : min;
  const lte = filter?.filter[0]?.[field]?.lte
    ? filter?.filter[0]?.[field]?.lte
    : max;

  const [value, setValue] = React.useState<number[]>([gte, lte]);

  const marks = [
    {
      value: min,
      label: formatPercentage(min),
    },
    {
      value: max,
      label: formatPercentage(max),
    },
  ];
  const handleChange = (event: any, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  function valuetext(value: number) {
    return `${value}°C`;
  }

  // console.log(process.env.NODE_ENV);
  // console.log(filter);
  const updateFilters = (event: any) => {
    setAddFilter({
      [field]: {
        gte: value[0],
        lte: value[1],
      },
    });
  };
  return (
    <Grid item xs={12} sm={6}>
      <Item>
        <Box sx={{ p: 2 }}>
          <Typography>
            <i>{translate(field)}</i>
          </Typography>
          <MuiSlider
            getAriaLabel={() => field}
            value={value}
            step={0.01}
            min={min}
            marks={marks}
            max={max}
            onChange={handleChange}
            onChangeCommitted={updateFilters}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
          />
        </Box>
      </Item>
    </Grid>
  );
};
