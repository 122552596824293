// context/todoContext.tsx
import * as React from "react";
import { useState } from "react";
import { translations } from "./translations";

export type LanguageContextType = {
  helloWorld: () => JSX.Element;
  language: string;
  languages: string[];
  translate: (keyTranslate: string) => String;
  setLanguage: (language: string) => void;
};

interface Props {
  children: React.ReactNode;
}

export const LanguageContext = React.createContext<LanguageContextType | null>(
  null
);

export const LanguageProvider: React.FC<Props> = ({ children }) => {
  const [language, setLanguage] = useState("en");

  const languages = ["en", "nl", "de", "es", "fr"];

  const helloWorld = () => {
    return <>Loading.GERMAN...</>;
  };

  const translate = (keyTranslate: string) => {
    //@ts-expect-error
    if (!translations[keyTranslate]) return keyTranslate + "^^";
    //@ts-expect-error
    return translations[keyTranslate][language];
  };
  return (
    <LanguageContext.Provider
      value={{ helloWorld, language, languages, translate, setLanguage }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
