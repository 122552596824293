import React from "react";
import PersistentDrawerLeft from "./Drawer";

import { useLocation } from "react-router-dom";

// import { Outlet } from "react-router-dom";
import ReactGA from "react-ga4";

ReactGA.initialize([
  {
    trackingId: "G-MHHHZEE240",
  },
]);

export const App = (props: any) => {
  let location = useLocation();

  React.useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
    });
  }, [location]);

  return (
    <>
      <PersistentDrawerLeft uri={props.uri} />
    </>
  );
};
