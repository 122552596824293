import * as React from "react";
import { useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { roundValue, formatPercentage, formatValuta } from "./utils";
import { Item } from "./Item";
import {
  LanguageContext,
  LanguageContextType,
} from "../context/languageContext";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Grid,
} from "@mui/material";

const FEED_QUERY = gql`
  query Fund($filter: [FundFilter]) {
    fund(filter: $filter) {
      ticker
      name
      inceptionDate
      price
      discount
      nav
      navDate
      sponsor
      leverageRatio
      totalReturnNav5
      yieldPrice
      fundType
      dxyxzScore
      returnOnCapital1
      returnOnCapital3
      niiCoverage
      niiTrend
      unii
      uniiTrend
      riskPyramid
      earningsCoverage
      category {
        categoryName
        id
      }
    }
  }
`;

export const Detail = () => {
  let params = useParams();
  const { language, translate } = React.useContext(
    LanguageContext
  ) as LanguageContextType;
  const filter = { filter: [{ ticker: params.ticker }] };
  const { error, loading, data } = useQuery(FEED_QUERY, {
    errorPolicy: "all",
    variables: filter,
  });
  if (loading) return <>Loading..</>;
  if (error) {
    return <>Error..</>;
  }

  const fund = data.fund[0];

  return (
    <>
      <Typography>Detail fund: </Typography>
      <Grid container>
        <Grid item xs={12} sm={2}>
          <Item>
            <Typography variant="h2">{fund.ticker}</Typography>
            <Typography>Ticker</Typography>
          </Item>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Item>
            <Typography variant="h2">{formatValuta(fund.price)}</Typography>
            <Typography>price</Typography>
          </Item>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Item>
            <Typography variant="h2">{formatValuta(fund.nav)} </Typography>
            <Typography>nav ({fund.navDate})</Typography>
          </Item>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Item>
            <Typography variant="h2">
              {formatPercentage(fund.discount)}
            </Typography>
            <Typography>{translate("discount")}</Typography>
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Leverage</TableCell>
                  <TableCell>{formatPercentage(fund.leverageRatio)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Return on Capital 1yr</TableCell>
                  <TableCell>{fund.returnOnCapital1}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Return on Capital 3yr</TableCell>
                  <TableCell>
                    {formatPercentage(fund.returnOnCapital3)}
                  </TableCell>
                </TableRow>
                {Object.keys(fund).map((key) => (
                  <TableRow>
                    <TableCell>{translate(key)}</TableCell>
                    <TableCell>{key}</TableCell>
                    <TableCell>{fund[key]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Item>
        </Grid>
      </Grid>
    </>
  );
};
