import * as React from "react";
import { useState, useEffect } from "react";

export type ScreenerContextType = {
  helloWorld: () => JSX.Element;
  filter: { filter: any };
  addFilter: {};
  resetFilter: () => void;
  setAddFilter: (addFilter: any) => void;
};

interface Props {
  children: React.ReactNode;
}

export const ScreenerContext = React.createContext<ScreenerContextType | null>(
  null
);

export const ScreenerProvider: React.FC<Props> = ({ children }) => {
  const [addFilter, setAddFilter] = useState({});
  const [filter, setFilter] = useState({ filter: [{ fundType: "CEF" }] });
  useEffect(() => {
    let currentfilter = filter.filter[0];
    setFilter({
      filter: [{ ...currentfilter, ...addFilter }],
    });
  }, [addFilter]);

  const helloWorld = () => {
    return <>Loading.GERMAN...</>;
  };

  const resetFilter = () => {
    setFilter({ filter: [{ fundType: "CEF" }] });
  };

  return (
    <ScreenerContext.Provider
      value={{ helloWorld, filter, addFilter, setAddFilter, resetFilter }}
    >
      {children}
    </ScreenerContext.Provider>
  );
};
