export const percentageFields = [
  "leverageRatio",
  "yieldPrice",
  "returnOnCapital1",
  "returnOnCapital3",
  "totalReturnNav5",
  "discount",
  "earningsCoverage",
  "niiCoverage",
  "niiTrend",
  "navGrowth5",
  "navGrowth10",
];

export const priceFields = ["nav", "price", "unii"];

export const numberFields = ["dxyxzScore", "riskPyramid"];
