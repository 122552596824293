import * as React from "react";
import { percentageFields, numberFields, priceFields } from "../data";
import { StyledTableCell } from "./StyledTableCell";
import { roundValue, formatPercentage, formatValuta } from "./utils";
import { Link } from "react-router-dom";
import {
  LanguageContext,
  LanguageContextType,
} from "../context/languageContext";

export const FormatValue = (props: any) => {
  const { language } = React.useContext(LanguageContext) as LanguageContextType;

  if (props.fieldName === "ticker")
    return (
      <StyledTableCell>
        <Link to={`/${language}/funds/${props.value}`}>{props.value}</Link>
      </StyledTableCell>
    );
  if (percentageFields.includes(props.fieldName))
    return (
      <StyledTableCell align="right">
        {formatPercentage(props.value)}
      </StyledTableCell>
    );
  if (numberFields.includes(props.fieldName))
    return (
      <StyledTableCell align="right">{roundValue(props.value)}</StyledTableCell>
    );
  if (priceFields.includes(props.fieldName))
    return (
      <StyledTableCell align="right">
        {formatValuta(props.value)}
      </StyledTableCell>
    );
  if (props.fieldName === "__typename") return <></>;
  return <StyledTableCell>{props.value}</StyledTableCell>;
};
