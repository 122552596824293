import { DashboardCard } from "./navigation/DashboardCard";
import {Adsense} from '@ctrl/react-adsense';

export const Dashboard = () => (
  <>
    <DashboardCard />
    <Adsense
  client="ca-pub-7676060129287016"
  slot="7259870550"
/>
  </>
);
