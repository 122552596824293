export const translations = {
  ticker: {
    en: "ticker",
    nl: "ticker",
    de: "ticker",
    es: "ticker",
    fr: "ticker",
  },
  name: {
    en: "Name",
    nl: "Naam",
    de: "Name",
    es: "Nombre",
    fr: "Nom",
  },
  category: {
    en: "Categorie",
    nl: "Categorie",
    de: "Kategorie",
    es: "Categoría",
    fr: "Catégorie",
  },
  price: {
    en: "Share Price",
    nl: "Prijs",
    de: "Aktienkurs",
    es: "Precio",
    fr: "Le cours",
  },
  nav: {
    en: "NAV",
    nl: "NAV",
    de: "NAV",
    es: "NAV",
    fr: "NAV",
  },
  leverage: {
    en: "Leverage",
    nl: "Prijs",
    de: "Hebelwirkung",
    es: "El Apalancamiento",
    fr: "L'effet de levier",
  },
  menuListFunds: {
    en: "All funds",
    nl: "Alle fondsen",
    de: "Alle fonds",
    es: "Los fondos",
    fr: "Tous les fonds",
  },
  menuHome: {
    en: "Home",
    nl: "Home",
    de: "Home",
    es: "Home",
    fr: "Home",
  },
  totalReturnNav5: {
    en: "5 year return on Nav",
    nl: "5 jaar rendement op Nav",
    de: "5 Jahre Rendite auf Nav",
    es: "5 años de retorno en Nav",
    fr: "5 ans de retour sur Nav",
  },
  yieldPrice: {
    en: "Yield % on price",
    nl: "Rendement % op price",
    de: "Rendite % auf den Preis",
    es: "rendimiento % sobre el precio",
    fr: "rendement % sur prix",
  },
  fundType: {
    en: "Type of fund",
    nl: "Type fonds",
    de: "Art des Fonds",
    es: "tipo de fondo",
    fr: "type de fonds",
  },
  discount: {
    en: "Discount/premium",
    nl: "Korting/toeslag",
    de: "Rabatt/prämie",
    es: "Descuento/prima",
    fr: "Rabais/prime",
  },
  returnOnCapital3: {
    en: "Return of capital (3yr)",
    nl: "Teruggave van kapitaal (3 jaar)",
    de: "Kapitalrückgabe (3 Jahre)",
    es: "Devolución de capital (3 años)",
    fr: "Remboursement de capital (3 ans)",
  },
  leverageRatio: {
    en: "Leverage ratio",
    nl: "Hefboomwerkingsratio",
    de: "Hebelverhältnis",
    es: "Ratio de apalancamiento",
    fr: "Ratio de levier",
  },
  earningsCoverage: {
    en: "Earnings coverage",
    nl: "Inkomsten dekking",
    de: "Ertragsdeckung",
    es: "Cobertura de ganancias",
    fr: "Couverture des revenus",
  },
};
